<div
  class="op-modal op-modal_autoheight loading-indicator--location"
  data-indicator-name="modal"
>
  <op-modal-header
    icon="icon-log_time"
    (close)="closeMe($event)">
    {{text.title}}
  </op-modal-header>

  <div class="ngdialog-body op-modal--body">
    <te-form #editForm
             [changeset]="changeset"
             [showWorkPackageField]="showWorkPackageField"
             [showUserField]="showUserField"
             (modifiedEntry)="setModifiedEntry($event)">
    </te-form>
  </div>

  <div class="op-modal--footer op-modal--footer_split-actions">
    <div>
      <button class="button -danger"
              *ngIf="deleteAllowed"
              (click)="destroy()"
              [textContent]="text.delete"
              [attr.title]="text.delete">
      </button>
    </div>
    <div>
      <button class="button"
              *ngIf="saveAllowed"
              (click)="closeMe($event)"
              [textContent]="text.cancel"
              [attr.title]="text.cancel">
      </button>
      <button class="button -highlight button_no-margin"
              *ngIf="saveAllowed"
              (click)="saveEntry()"
              [attr.title]="text.save"
              [textContent]="text.save"
              [disabled]="formInFlight">
      </button>
    </div>
  </div>
</div>
