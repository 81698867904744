<ng-container
  *ngIf="resource"
>
  <op-storage-information
    *ngFor="let infoBox of storageInformation | async"
    class="op-files-tab--storage-info-box"
    data-qa-selector="op-files-tab--storage-information"
    [viewModel]="infoBox"
  ></op-storage-information>

  <ul
    class="spot-list spot-list_compact op-files-tab--file-list"
    data-qa-selector="op-files-tab--file-list"
  >
    <li
      *ngFor="let fileLink of fileLinks$ | async; let i = index;"
      class="spot-list--item op-files-tab--file-list-item"
      data-qa-selector="op-files-tab--file-list-item"
      op-file-link-list-item
      [fileLink]="fileLink"
      [disabled]="disabled"
      [allowEditing]="allowEditing$ | async"
      (removeFileLink)="removeFileLink(fileLink)"
    ></li>
  </ul>

  <p
    *ngIf="showLinkFilesAction | async"
    class="spot-body-small"
  >
    <a
      class="spot-link op-files-tab--file-list-action-button"
      (click)="openStorageLocation()"
    >
      <span class="spot-icon spot-icon_external-link"></span>
      <span [textContent]="text.actions.linkFile(storageType)"></span>
    </a>
  </p>
</ng-container>
