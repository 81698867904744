<form
  class="op-modal op-datepicker-modal loading-indicator--location"
  data-qa-selector="op-datepicker-modal"
  [attr.id]="htmlId"
  #modalContainer
  data-indicator-name="modal"
  (submit)="save($event)"
>
  <op-datepicker-banner [scheduleManually]="scheduleManually"></op-datepicker-banner>
  <div class="op-modal--body form -vertical">
    <div class="form--field op-datepicker-modal--scheduling-action-container">
      <div class="form--field-container">
        <label class="form--label-with-check-box">
          <div class="form--check-box-container">
            <input type="checkbox"
                   name="scheduling"
                   class="form--check-box op-datepicker-modal--scheduling-action"
                   data-qa-selector="op-datepicker-modal--scheduling-action"
                   [ngModel]="scheduleManually"
                   (ngModelChange)="changeSchedulingMode()">
          </div>
          {{ text.manualScheduling }}
        </label>
      </div>
    </div>

    <div class="op-datepicker-modal--dates-container">
      <ng-container *ngIf="singleDate">
        <div class="form--field op-datepicker-modal--date-form">
          <label class="form--label"
                 [textContent]="text.date">
          </label>
          <div class="form--field-container">
            <div class="form--text-field-container op-datepicker-modal--date-container">
              <spot-text-field
                     name="date"
                     class="op-datepicker-modal--date-field"
                     [ngClass]="{'op-datepicker-modal--date-field_current' : showFieldAsActive('date')}"
                     [(ngModel)]="dates.date"
                     (ngModelChange)="dateChangedManually$.next()"
                     [showClearButton]="datepickerService.isStateOfCurrentActivatedField('date')"
                     (click)="datepickerService.setCurrentActivatedField('date')"
              ></spot-text-field>
            </div>
          </div>
          <div class="form--field-extra-actions">
            <a (click)="setToday('date')"
               [textContent]="text.today">
            </a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!singleDate">
        <div class="form--field op-datepicker-modal--date-form"
             data-qa-selector="datepicker-start-date"
        >
          <label class="form--label"
                 [textContent]="text.startDate">
          </label>
          <div class="form--field-container">
            <div class="form--text-field-container op-datepicker-modal--date-container">
              <spot-text-field
                     name="startDate"
                     data-qa-selector="op-datepicker-modal--start-date-field"
                     class="op-datepicker-modal--date-field"
                     [ngClass]="{'op-datepicker-modal--date-field_current' : showFieldAsActive('start')}"
                     [(ngModel)]="dates.start"
                     (ngModelChange)="dateChangedManually$.next()"
                     [disabled]="!isSchedulable"
                     [showClearButton]="datepickerService.isStateOfCurrentActivatedField('start')"
                     (focusin)="datepickerService.setCurrentActivatedField('start')"
              ></spot-text-field>
            </div>
          </div>
          <div class="form--field-extra-actions">
            <a *ngIf="showTodayLink()"
               (click)="setToday('start')"
               [textContent]="text.today">
            </a>
          </div>
        </div>
        <div class="form--field op-datepicker-modal--date-form"
             data-qa-selector="datepicker-end-date"
        >
          <label class="form--label"
                 [textContent]="text.endDate">
          </label>
          <div class="form--field-container">
            <div class="form--text-field-container op-datepicker-modal--date-container">
              <spot-text-field
                     name="endDate"
                     data-qa-selector="op-datepicker-modal--end-date-field"
                     class="op-datepicker-modal--date-field"
                     [ngClass]="{'op-datepicker-modal--date-field_current' : showFieldAsActive('end')}"
                     [(ngModel)]="dates.end"
                     (ngModelChange)="dateChangedManually$.next()"
                     [disabled]="!isSchedulable"
                     [showClearButton]="datepickerService.isStateOfCurrentActivatedField('end')"
                     (focusin)="datepickerService.setCurrentActivatedField('end')"
              ></spot-text-field>
            </div>
          </div>
          <div class="form--field-extra-actions">
            <a *ngIf="showTodayLink()"
               (click)="setToday('end')"
               [textContent]="text.today">
            </a>
          </div>
        </div>
      </ng-container>
    </div>

    <input id="flatpickr-input"
           hidden>
  </div>

  <div class="op-modal--footer">
    <button
      (click)="cancel()"
      class="op-datepicker-modal--action button"
      data-qa-selector="op-datepicker-modal--action"
      [textContent]="text.cancel"
    ></button>
    <button
      class="op-datepicker-modal--action button -highlight"
      data-qa-selector="op-datepicker-modal--action"
      [textContent]="text.save"
    ></button>
  </div>
</form>
