<div
  class="spot-list--item-floating-wrapper op-files-tab--file-list-item-floating-wrapper"
  [ngClass]="{ 'op-files-tab--file-list-item-floating-wrapper_disabled': disabled }"
>
  <a
    class="spot-list--item-action spot-list--item-action_link op-files-tab--file-list-item-action"
    [ngClass]="{ 'disabled': disabled, 'view-not-allowed': !viewAllowed }"
    [href]="fileLink._links.staticOriginOpen.href"
    [title]="fileLink.originData.name"
    target="_blank"
  >
    <span
      class="spot-icon spot-icon_{{fileLinkIcon.icon}} op-files-tab--icon op-files-tab--icon_{{fileLinkIcon.clazz}}"
    ></span>

    <span
      [textContent]="fileLink.originData.name"
      [title]="fileLink.originData.name"
      class="spot-list--item-title op-files-tab--file-list-item-title"
    ></span>

    <span
      class="op-files-tab--file-list-item-text"
      [textContent]="infoTimestampText"
    ></span>

    <div
      #avatar
      class="op-files-tab--file-list-item-avatar"
    ></div>
  </a>
  <div
    *ngIf="!disabled && viewAllowed"
    class="spot-list--item-floating-actions op-files-tab--file-list-item-floating-actions hidden-for-mobile"
  >
    <a
      *ngIf="downloadAllowed"
      class="spot-link"
      [title]="text.title.downloadFileLink"
      [href]="fileLink._links.staticOriginDownload.href"
    >
      <span class="spot-icon spot-icon_download-arrow"></span>
    </a>
    <a
      class="spot-link"
      [title]="text.title.openFileLocation"
      [href]="fileLink._links.staticOriginOpenLocation.href"
      target="_blank"
    >
      <span class="spot-icon spot-icon_folder-open"></span>
    </a>
    <button
      *ngIf="allowEditing"
      class="spot-link"
      [title]="text.title.removeFileLink"
      (click)="confirmRemoveFileLink()"
    >
      <span class="spot-icon spot-icon_remove-link"></span>
    </button>
  </div>
  <div
    *ngIf="!disabled && !viewAllowed"
    class="spot-list--item-floating-actions op-files-tab--file-list-item-floating-text hidden-for-mobile"
  >
    <span class="spot-icon spot-icon_info2 op-files-tab--file-list-item-floating-text-icon"></span>
    <span [textContent]="text.floatingText.noViewPermission"></span>
  </div>
</div>
